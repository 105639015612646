<template>
  <div class="acc-wrap">
    <div class="acc-content">
      <h3>个人资料补充</h3>
      <div class="step">
        <h3>完善个人信息</h3>
        <div class="id_tip">
          上传身份证照片
        </div>
        <div class="card-upload">
          <div
            v-show="idcardUrl.length === 0"
            class="img-wrap"
          >
            <img src="@/assets/images/myFavors/idbg.png">
          </div>
          <div
            v-for="(item, index) in idcardUrl"
            :key="index"
            class="img-wrap"
          >
            <img :src="item">
            <div
              class="img-mask"
              @click="handleRemoveImg(index)"
            >
              <i class="iconfont icon-form-idcard-error" />
            </div>
          </div>
          <img src="@/assets/images/myFavors/idDemo.png">
        </div>
        <div class="upload-btn">
          <span
            class="btn"
            @click="uploadIdCard"
          >上传证件</span>
          <span class="tips"><span>*</span>&ensp;&ensp;<em>请按照拍摄规范依次上传身份证正面(人像面)、身份证反面(国徽面)，若顺序错误可能导致识别失误</em></span>
        </div>
        <div class="form-wrap">
          <el-form
            ref="auth"
            :model="auth"
            :rules="authRules"
            label-position="top"
          >
            <div class="check-info">
              <h3>核对信息</h3>
              <el-form-item
                label="姓名"
                class="card-item"
                prop="clientName"
              >
                <el-input
                  v-model="auth.clientName"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                label="性别"
                class="card-item"
                prop="clientGender"
              >
                <el-select
                  v-model="auth.clientGender"
                  class="pw-inpput"
                >
                  <el-option
                    value="0"
                    label="男"
                  />
                  <el-option
                    value="1"
                    label="女"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="年龄（自动计算）"
                class="card-item"
                prop="clientAge"
              >
                <el-input
                  disabled
                  v-model="auth.clientAge"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                label="证件类型"
                class="card-item"
                prop="idKindGb"
              >
                <el-select
                  v-model="auth.idKindGb"
                  class="pw-inpput"
                >
                  <el-option
                    v-for="item in sysIdType"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="证件号码"
                class="card-item"
                prop="idNo"
              >
                <el-input
                  v-model="auth.idNo"
                  class="pw-inpput"
                  disabled
                />
              </el-form-item>
              <el-form-item
                label="证件有效期"
                class="card-item"
                prop="idEnddate"
              >
                <el-select
                  v-model="idValidate"
                  class="select-h50"
                  @change="idValidateChange"
                >
                  <el-option
                    label="时限有效"
                    value="1"
                  />
                  <el-option
                    label="长期有效"
                    value="2"
                  />
                </el-select>
                <el-date-picker
                  v-show="idValidate === '1'"
                  v-model="auth.idEnddate"
                  value-format="yyyyMMdd"
                  format="yyyy.MM.dd"
                  class="pw-inpput"
                  style="width:290px !important;margin-left:25px"
                  type="date"
                  placeholder="请选择证件有效期"
                />
              </el-form-item>
              <el-form-item
                label="国籍"
                class="card-item"
                prop="fundNationality"
              >
                <el-select
                  v-model="auth.fundNationality"
                  class="pw-inpput"
                >
                  <el-option
                    v-for="item in nationalDics"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
              <!-- <el-form-item
                label="所在地区"
                class="card-item"
                prop="homeAddrCode"
              >
                <el-cascader
                  v-model="auth.homeAddrCode"
                  class="pw-inpput"
                  :props="{
                    label: 'name',
                    value: 'code'
                  }"
                  :options="chinaRegions"
                  @change="handleChange1"
                />
              </el-form-item> -->
              <el-form-item
                label="地址"
                class="card-item"
                prop="idAddress"
              >
                <el-input
                  v-model="auth.idAddress"
                  class="pw-inpput"
                />
              </el-form-item>
            </div>
            <div class="info-supply">
              <h3>核对信息</h3>
              <el-form-item
                label="职业类别"
                class="card-item"
                prop="ofundProfCode"
              >
                <el-select
                  v-model="auth.ofundProfCode"
                  style="width:100%"
                >
                  <el-option
                    v-for="item in jobDics"
                    :key="item.val"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="年收入"
                class="card-item"
                prop="income"
              >
                <el-select
                  v-model="auth.income"
                  style="width:100%"
                >
                  <el-option
                    v-for="item in incomeDics"
                    :key="item.val"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="账户实际受益人"
                class="card-item"
                prop="beneficiaryType"
              >
                <el-select
                  v-model="auth.beneficiaryType"
                  class="pw-inpput"
                  @change="beneficiaryTypeChange"
                >
                  <el-option
                    value="1"
                    label="本人"
                  />
                  <el-option
                    value="2"
                    label="他人"
                  />
                </el-select>
              </el-form-item>
            </div>
            <!-- 实际受益人为他人信息补齐 -->
            <div
              v-if="auth.beneficiaryType === '2'"
              class="check-info"
            >
              <h3>实际受益人信息补齐</h3>
              <el-form-item
                label="姓名"
                class="card-item"
                prop="beneficiary"
              >
                <el-input
                  v-model="auth.beneficiary"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                label="性别"
                class="card-item"
                prop="beneficiaryGender"
              >
                <el-select
                  v-model="auth.beneficiaryGender"
                  class="pw-inpput"
                >
                  <el-option
                    value="0"
                    label="男"
                  />
                  <el-option
                    value="1"
                    label="女"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="年龄"
                class="card-item"
              >
                <el-input
                  disabled
                  :value="getAge1(auth.beneficiaryBirthday)"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                class="card-item"
                label="证件类型"
                prop="beneficiaryIdType"
              >
                <el-select
                  v-model="auth.beneficiaryIdType"
                  class="pw-inpput"
                >
                  <el-option
                    v-for="item in sysIdType"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="card-item"
                label="证件号码"
                prop="beneficiaryIdno"
              >
                <el-input
                  v-model="auth.beneficiaryIdno"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                class="card-item"
                label="证件有效期"
                prop="beneficiaryIdValidate"
              >
                <el-date-picker
                  v-model="auth.beneficiaryIdValidate"
                  value-format="yyyyMMdd"
                  format="yyyy.MM.dd"
                  class="pw-inpput"
                  :clearable="false"
                  type="date"
                />
              </el-form-item>
              <el-form-item
                class="card-item"
                label="出生日期"
                prop="beneficiaryBirthday"
              >
                <el-date-picker
                  v-model="auth.beneficiaryBirthday"
                  value-format="yyyyMMdd"
                  format="yyyy.MM.dd"
                  class="pw-inpput"
                  :clearable="false"
                  type="date"
                />
              </el-form-item>
              <el-form-item
                class="card-item"
                label="国籍"
                prop="beneficiaryNationality"
              >
                <el-select
                  v-model="auth.beneficiaryNationality"
                  class="pw-inpput"
                >
                  <el-option
                    v-for="item in nationalDics"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="card-item"
                label="职业类别"
                prop="beneficiaryProfCode"
              >
                <el-select
                  v-model="auth.beneficiaryProfCode"
                  class="pw-inpput"
                >
                  <el-option
                    v-for="item in jobDics"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="card-item"
                label="电话"
                prop="beneficiaryTel"
              >
                <el-input
                  v-model="auth.beneficiaryTel"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                class="card-item"
                label="邮箱"
                prop="beneficiaryEMail"
              >
                <el-input
                  v-model="auth.beneficiaryEMail"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                class="card-item"
                label="邮编"
                prop="beneficiaryZipcode"
              >
                <el-input
                  v-model="auth.beneficiaryZipcode"
                  class="pw-inpput"
                />
              </el-form-item>
              <!-- <el-form-item
                class="card-item"
                label="所在地区"
                prop="beneficiaryAddrCode"
              >
                <el-cascader
                  v-model="auth.beneficiaryAddrCode"
                  class="pw-inpput"
                  :props="{
                    label: 'name',
                    value: 'code'
                  }"
                  :options="chinaRegions"
                  @change="handleChange"
                />
              </el-form-item> -->
              <el-form-item
                class="card-item"
                label="地址"
                prop="beneficiaryAddress"
              >
                <el-input
                  v-model="auth.beneficiaryAddress"
                  class="pw-inpput"
                />
              </el-form-item>
            </div>
            <div class="info-supply">
              <el-form-item
                label="税收居民身份声明"
                class="card-item"
                prop="taxIdentity"
              >
                <el-select
                  v-model="auth.taxIdentity"
                  style="width:100%"
                >
                  <el-option
                    v-for="item in taxDics"
                    :key="item.val"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <!-- <el-form-item
                label="电话"
                class="card-item"
                prop="mobileTel"
              >
                <el-input
                  v-model="auth.mobileTel"
                  class="pw-inpput"
                />
              </el-form-item> -->
              <el-form-item
                label="邮编"
                class="card-item"
                prop="zipcode"
              >
                <el-input
                  v-model="auth.zipcode"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item
                label="邮箱"
                class="card-item"
                prop="email"
              >
                <el-input
                  v-model="auth.email"
                  class="pw-inpput"
                />
              </el-form-item>
              <el-form-item style="margin-top:44px">
                <span
                  class="n-btn"
                  @click="handleSubmit"
                >提交资料</span>
                <span
                  class="t-btn"
                  @click="$router.go(-1)"
                >返回</span>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CHINA_REGIONS = require('../../../assets/regions.json')
import {mapGetters} from 'vuex'
import { clientInfoMod, ocrIdCard } from '@/api/myAcc'
import { uploadFile } from '@/api/commom'
import { popFileSelector } from '@/utils/debounce'
import { zipCodeCheck, emailCheck, phoneCheck, idCardCheck, chineseCheck } from '@/utils/checkReg.js'
function getArr(arr) {
  const s = []
  arr.forEach(item => {
    s.push({
      code: item.code,
      name: item.name
    })
    if (item.children) {
      s.push(...getArr(item.children))
    }
  })
  return s
}
const REGIONS_ARR = getArr(CHINA_REGIONS)
const REGIONS_MAP = {}
REGIONS_ARR.forEach(item => {
  REGIONS_MAP[item.code] = item.name
})
  export default {
    data() {
      return {
        idValidate: '1',
        idcardUrl: [],
        // 税收居民身份
        taxDics:[
					{
						value:'0',
						text: '仅为中国税收居民'
					},
					{
						value:'1',
						text: '仅为非中国税收居民'
					},
					{
						value:'2',
						text: '既是中国税收居民也是其他国家（地区）'
					},
				],
        // 省市区
        chinaRegions: CHINA_REGIONS,
        // 证件类型字典
				sysIdType: [],
				// 国籍字典
				nationalDics: [],
				// 职业类别
				jobDics: [],
				// 年收入字典
				incomeDics: [],

        auth: {
          idPhotoPositive:'',
          idPhotoNegative: '',
          clientName: '',
          clientGender: '',
          clientAge: '',
          idKindGb: '',
          idNo: '',
          idEnddate: '',
          fundNationality: '',
          // homeAddrCode: '',
          idAddress: '',
          // homeAddrPrivince: '',
          // homeAddrCity: '',
          // homeAddrDistrict: '',
          ofundProfCode: '',
          income: '',
          beneficiaryType: '',
          taxIdentity: '',
          // mobileTel: '',
          zipcode: '',
          email: '',
          nation: '',
          idOrgan: '',


          beneficiary: '',
          beneficiaryGender: '',
          beneficiaryNationality: '',
          beneficiaryIdType: '',
          beneficiaryIdno: '',
          beneficiaryIdValidate: '',
          // beneficiaryAddrCode: '',
          // beneficiaryAddrPrivince: '',
          // beneficiaryAddrCity: '',
          // beneficiaryAddrDistrict: '',
          beneficiaryAddress: '',
          beneficiaryBirthday: '',
          beneficiaryProfCode: '',
          beneficiaryTel: '',
          beneficiaryEMail: '',
          beneficiaryZipcode: ''
        },
        authRules: {
          clientName:[
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { pattern: chineseCheck, message: '请输入中文' },
          ],
          clientGender:[
            { required: true, message: '请选择性别', trigger: 'blur' }
          ],
          clientAge: [
            { required: true, message: '请输入年龄', trigger: 'blur' }
          ],
          idKindGb: [
            { required: true, message: '请选择证件类型', trigger: 'change' }
          ],
          idNo: [
            { required: true, message: '请输入证件号码', trigger: 'blur' },
            { pattern: idCardCheck, message: '证件号码格式不正确' }
          ],
          idEnddate: [
            { required: true, message: '请选择证件有效期', trigger: 'blur' },
          ],
          fundNationality: [
            { required: true, message: '请选择国籍', trigger: 'change' }
          ],
          // homeAddrCode: [
          //   { required: true, message: '请选择地区', trigger: 'change' }
          // ],
          idAddress: [
            { required: true, message: '请输入地址', trigger: 'change' }
          ],
          ofundProfCode: [
            { required: true, message: '请选择职业类别', trigger: 'change' }
          ],
          income: [
            { required: true, message: '请选择年收入', trigger: 'change' }
          ],
          beneficiaryType: [
            { required: true, message: '请选择账户受益人', trigger: 'change' }
          ],
          taxIdentity: [
            { required: true, message: '请选择税收居民身份', trigger: 'change' }
          ],
          // mobileTel: [
          //   { required: true, message: '请输入电话号码', trigger: 'blur' },
          //   { pattern: phoneCheck, message: '证件号码格式不正确' }
          // ],
          zipcode: [
            { required: true, message: '请输入邮编', trigger: 'blur' },
            { pattern: zipCodeCheck, message: '邮编格式不正确' }
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { pattern: emailCheck, message: '邮编格式不正确' }
          ],
          beneficiary: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          beneficiaryGender: [
            { required: true, message: '请选择性别', trigger: 'blur' }
          ],
          beneficiaryIdType: [
            { required: true, message: '请选择证件类别', trigger: 'blur' }
          ],
          beneficiaryIdno: [
            { required: true, message: '请输入证件号码', trigger: 'blur' },
            { pattern: idCardCheck, message: '证件号码格式不正确' }
          ],
          beneficiaryIdValidate: [
            { required: true, message: '请选择证件有效期', trigger: 'blur' }
          ],
          beneficiaryBirthday: [
            { required: true, message: '请选择出生日期', trigger: 'blur' }
          ],
          beneficiaryNationality: [
            { required: true, message: '请选择国籍', trigger: 'blur' }
          ],
          beneficiaryProfCode: [
            { required: true, message: '请选择职业类别', trigger: 'blur' }
          ],
          beneficiaryTel: [
            { required: true, message: '请输入电话号码', trigger: 'blur' },
            { pattern: phoneCheck, message: '电话号码格式不正确' }
          ],
          beneficiaryEMail: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ],
          beneficiaryZipcode: [
            { required: true, message: '请输入邮编', trigger: 'blur' },
            { pattern: zipCodeCheck, message: '邮编格式不正确' }
          ],
          // beneficiaryAddrCode: [
          //   { required: true, message: '请选择省市区', trigger: 'blur' }
          // ],
          beneficiaryAddress: [
            { required: true, message: '请输入地址', trigger: 'blur' }
          ]
        }
      }
    },
    created () {
      this.getDicts('sys_id_type').then(res => {
				this.sysIdType = res.data
			})
			this.getDicts('sys_user_nationality').then(res => {
				this.nationalDics = res.data
        this.auth.fundNationality = res.data.find(m => m.dictLabel === '中国').dictValue
			})
			this.getDicts('sys_user_job_type').then(res => {
				this.jobDics = res.data
			})
			this.getDicts('income_type').then(res => {
				this.incomeDics = res.data
			})
    },
    computed: {
      ...mapGetters(["mobile"])
    },
    methods: {
      beneficiaryTypeChange() {
        this.auth = {
          ...this.auth,
          beneficiary: '',
          beneficiaryNationality: '',
          beneficiaryIdType: '',
          beneficiaryIdno: '',
          beneficiaryIdValidate: '',
          // beneficiaryAddrCode: '',
          // beneficiaryAddrPrivince: '',
          // beneficiaryAddrCity: '',
          // beneficiaryAddrDistrict: '',
          beneficiaryAddress: '',
          beneficiaryGender: '',
          beneficiaryBirthday: '',
          beneficiaryProfCode: '',
          beneficiaryTel: '',
          beneficiaryEMail: '',
          beneficiaryZipcode: '',
        }
      },
      idValidateChange(val) {
        if (val === '2') {
          this.auth.idEnddate = '99991231'
        } else {
          this.auth.idEnddate = ''
        }
      },
      getAge1(str) {
        var returnStr="0"
        if(str == ""|| str==null){
          returnStr = "0"
        }else{
          const _str = str.slice(0,4) + '-' + str.slice(4,6) + '-' + str.slice(-2)
          let workTime = new Date(_str.replace(/-/g, "/"));
          let d = new Date();
          let age = d.getFullYear() - workTime.getFullYear() - (d.getMonth() < workTime.getMonth() || (d.getMonth() == workTime.getMonth() && d.getDate() < workTime.getDate()) ? 1 : 0);
          returnStr = age + 1;
        }
        return returnStr;
			},
      handleRemoveImg(i) {
        this.idcardUrl.splice(i, 1)
      },
      uploadIdCard() {
        if (this.idcardUrl.length >= 2) {
          return
        }
        popFileSelector().then(file => {
          const fd = new FormData()
          fd.append('file', file)
          fd.append('folder', '/img/ocr/'+this.mobile+'/')
          uploadFile(fd).then(res => {
            this.idcardUrl.push(res.data)
            if (this.idcardUrl.length === 2) {
              this.ocrIdCard()
            }
          })
        })
      },
      async ocrIdCard() {
        // 识别身份证正面
				const imgAInfo = JSON.parse((await ocrIdCard({
					imgUrl: this.idcardUrl[0],
          // outImg: '1'
				})).data)
				// 识别身份证背面
				const imgBInfo = JSON.parse((await ocrIdCard({
					imgUrl: this.idcardUrl[1],
          // outImg: '1'
				})).data)
        
        const { BIRTHDAY, NUM, SEX, NAME, ADDRESS, FOLK } = imgAInfo
				const { PERIOD, ISSUE } = imgBInfo
        if (
          // BIRTHDAY === '' ||
					NUM === '' ||
					SEX === '' ||
					NAME === '' ||
					ADDRESS === '' ||
					FOLK ==='' ||
					PERIOD === '' ||
					ISSUE === ''
				) {
          this.$message({
            message: '身份证基本信息未识别成功，请正确上传身份证',
            type: 'warning',
          })
					return
				}
        const reg = /^(\d{4})(\d{2})(\d{2})$/
        // 校验出生日期格式是否正确
        // const idBithDate = BIRTHDAY.replace(/[\u4e00-\u9fa5]/g, '')
        const idBithDate = ('' + NUM).slice(6,14)
        if (!(reg.test(idBithDate) && RegExp.$2<=12 && RegExp.$3<=31)) {
          this.$message({
            message: '身份证正面照片不规范，请重新上传',
            type: 'warning',
          })
          return
        }
        // 校验有效期格式是否正确
        const idEnddate = PERIOD.split('-')[1] === '长期' ? '99991231' : PERIOD.split('-')[1].replace(/[.]/g, '')
        
        if (!(reg.test(idEnddate) && RegExp.$2<=12 && RegExp.$3<=31)) {
          this.$message({
            message: '身份证背面照片不规范，请重新上传',
            type: 'warning',
          })
          return
        }
        if (idEnddate === '99991231') {
          this.idValidate = '2'
        }
        this.auth.idEnddate = idEnddate
        this.auth.clientAge = this.getAge1(idBithDate)
        this.auth.clientName = NAME
        this.auth.idNo = NUM
        this.auth.clientGender = SEX === '男' ? '0' : '1'
        // this.auth.idEnddate = PERIOD.split('-')[1] == '长期' ? '99991231' : PERIOD.split('-')[1].replace(/[.]/g, '-')
        this.auth.idAddress = ADDRESS
        this.auth.nation = FOLK
        this.auth.idOrgan = ISSUE
        // ocrIdCard({
        //   urlPaths: this.idcardUrl
        // }).then(({data}) => {
        //   const [{/*birthday,*/ idCardNo, sex, name}, { endDate }] = data
        //   this.auth.clientName = name
        //   this.auth.idNo = idCardNo
        //   this.auth.clientGender = sex === '男' ? '0' : '1'
        //   this.auth.idEnddate = endDate.replace(/[.]/g, '')
        // })
      },
      // handleChange(val) {
      //   this.auth.beneficiaryAddrPrivince = REGIONS_MAP[val[0]]
      //   this.auth.beneficiaryAddrCity = REGIONS_MAP[val[1]]
      //   this.auth.beneficiaryAddrDistrict = REGIONS_MAP[val[2]]
      //   this.auth.beneficiaryAddrCode = val[2]
      // },
      // handleChange1(val) {
      //   this.auth.homeAddrPrivince = REGIONS_MAP[val[0]]
      //   this.auth.homeAddrCity = REGIONS_MAP[val[1]]
      //   this.auth.homeAddrDistrict = REGIONS_MAP[val[2]]
      //   this.auth.homeAddrCode = val[2]
      // },
      handleSubmit() {
        if (this.idcardUrl.length < 2) {
          this.$message({
            message: '请上传身份证',
            type: 'warning'
          });
					return
        }
        this.$refs['auth'].validate((valid) => {
          if(valid) {
            clientInfoMod({
              clientName: this.auth.clientName,
              clientGender: this.auth.clientGender,
              clientAge: this.auth.clientAge,
              idKindGb: this.auth.idKindGb,
              idNo: this.auth.idNo,
              idEnddate: this.auth.idEnddate,
              fundNationality: this.auth.fundNationality,
              idPhotoPositive: this.idcardUrl[0],
              idPhotoNegative: this.idcardUrl[1],
              nation: this.auth.nation,
              idOrgan: this.auth.idOrgan,
              // idAddrPrivince: this.auth.homeAddrPrivince,
              // idAddrCity: this.auth.homeAddrCity,
              // idAddrDistrict: this.auth.homeAddrDistrict,
              // idAddrCode: this.auth.homeAddrCode,
              idAddress: this.auth.idAddress,
              zipcode: this.auth.zipcode,
              ofundProfCode: this.auth.ofundProfCode,
              income: this.auth.income,
              taxIdentity: this.auth.taxIdentity,
              email: this.auth.email,
              beneficiaryType: this.auth.beneficiaryType,
              beneficiary: this.auth.beneficiary,
              beneficiaryNationality: this.auth.beneficiaryNationality,
              beneficiaryIdno: this.auth.beneficiaryIdno,
              beneficiaryIdValidate: this.auth.beneficiaryIdValidate,
              // beneficiaryAddrPrivince: this.auth.beneficiaryAddrPrivince,
              // beneficiaryAddrCity: this.auth.beneficiaryAddrCity,
              // beneficiaryAddrDistrict: this.auth.beneficiaryAddrDistrict,
              // beneficiaryAddrCode: this.auth.beneficiaryAddrCode,
              beneficiaryAddress: this.auth.beneficiaryAddress,
              beneficiaryGender: this.auth.beneficiaryGender,
              beneficiaryBirthday: this.auth.beneficiaryBirthday,
              beneficiaryProfCode: this.auth.beneficiaryProfCode,
              beneficiaryTel: this.auth.beneficiaryTel,
              beneficiaryZipcode: this.auth.beneficiaryZipcode,
              beneficiaryIdType: this.auth.beneficiaryIdType,
              beneficiaryEMail: this.auth.beneficiaryEMail,
            }).then(() => {
              this.$store.dispatch('GetInfo').then(() => {
                const _this = this
                this.$message({
                  message: '资料补充成功',
                  type: 'success',
                  onClose() {
                    _this.$router.push({
                      path: '/'
                    })
                  }
                })
              })
            })
          }
        })
      }
    },
  }
</script>

<style lang="less" scoped>
 @import url('@/assets/css/zc-common.less');
.acc-wrap {
  width: 100%;
  background-color: #F3F4F6;
  padding: 35px 0;
  .acc-content {
    width: 1200px;
    min-height: 900px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 4px 12px #EDF1F7;
    display: flex;
    flex-direction: column;
    &>h3 {
      width: 100%;
      height: 145px;
      line-height: 145px;
      text-align: center;
      box-shadow: 0px 4px 12px #EDF1F7;
      font-size: 24px;
      font-weight: bold;
      color: #CE9B63;
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 50%;
        background: linear-gradient(109.88deg, #F7D9B7 -3.02%, #EABF96 98.27%);
      }
    }
  }
}
.step {
  width: 100%;
  flex: 1;
  padding-top: 42px;
  padding-left: 190px;
  padding-right: 390px;
  padding-bottom: 42px;
  &>h3 {
    font-weight: bold;
    font-size: 18px;
    color: #8691A8;
  }
  .id_tip {
    margin-top: 44px;
    font-size: 16px;
    color: #1F1F1F;
  }
  .card-upload {
    .border-box();
    width: 100%;
    margin-top: 14px;
    .flex(flex-start, flex-start, row);
    .img-wrap {
      width: 174px;
      height: 112px;
      .flex(center, center, row);
      border: 1px solid #E0E2E8;
      position: relative;
      &>img {
        width: 162px;
        height: 100px;
      }
      &>.img-mask {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        background: #FFFFFF;
        opacity: 0.6;
        z-index: 1;
        &>i {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          width: 30px;
          height: 30px;
          background: rgba(0, 0, 0, 1);
        }
      }
    }
    &>img {
      width: 221.59px;
      height: 136.71px;
      margin-left: 20px;
    }
  }
  .upload-btn {
    width: 100%;
    margin-top: 20px;
    .flex(flex-start, center, row);
    .btn {
      .inline-flex(center, center, row);
      width: 174px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #CE9B63;
      border-radius: 2px;
      font-size: 14px;
      color: #CE9B63;
    }
    .tips {
      font-size: 12px;
      color: #8691A8;
      display: inline-flex;
      align-items: center;
      &>span {
        margin-left: 14px;
        color: #B88141;
      }
      &>em {
        font-style: normal;
      }
    }
  }
}
.form-wrap {
  width: 100%;
  margin-top: 36px;
  .check-info {
    width: 100%;
    padding: 38px 70px 38px 30px;
    border: 2px dashed #E0E2E8;
    background: #F8F7F7;
    &>h3 {
      font-weight: bold;
      font-size: 18px;
      color: #1F1F1F;
      margin-bottom: 12px;
    }
  }
  .info-supply {
    width: 520px;
    margin-top: 24px;
    &>h3 {
      color: #8691A8;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
}
.n-btn {
  .btn(200px, 50px);
}
.t-btn {
  margin-left: 28px;
  font-size: 16px;
  color: #CE9B63;
}
</style>
<style>
.pw-inpput {
  width:100% !important;
  height:50px !important
}
.pw-inpput .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
.pw-inpput .el-input__suffix {
  right: 20px !important;
  display: flex;
  align-items: center;
}
.input-with-select .el-input-group__prepend {
  width: 65px !important;
}
.card-item .el-form-item__label{
  font-size: 14px !important;
  color: #1F1F1F !important;
  padding: 0 !important;
}
.select-h50 {
  width:200px !important;
  height:50px !important
}
.select-h50 .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
</style>